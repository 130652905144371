<template>
  <div class="box404">
    <img src="../../assets/img/404.png" alt="">

      <div class="flex align-center justify-center">
          <el-button class="submit-btn" type="primary" @click="goBack">返回首页</el-button>
      </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {},
    mounted () {
      // 路由使用的是 history ， 所以监听 history
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.goBack, false)
      }
    },
    // 离开组件时，取消监听
    destroyed(){
      window.removeEventListener('popstate', this.goBack, false);
    },
    methods: {
      goBack(){
        this.$router.replace({path: '/'})
      }
    },
    components: {},
  }
</script>

<style scoped lang="scss">
  .box404 {
    width: 100%;
    height: 100%;
    min-height: 600px;
    img{
      display: block;
      width: 573px;
      margin: 100px auto;
    }
  }
</style>
